import { createSelector } from "reselect";
import { RootState } from "..";

export const getBriefElementsConfiguratorState = createSelector(
  (state: RootState) => state,
  (state) => state.briefElementConfigurator
);

export const getBriefElementsCount = createSelector(
  (state: RootState) => state,
  (state) => Object.keys(state.briefElementConfigurator).length
);
