import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { MyBreadcrumbs } from "../../../../components/common/MyBreadcrumbs";
import { Page } from "../../../../components/common/MyPage";
import { LoaderComponent } from "../../../../components/common/DynamicElement/LoaderComponent";
import { GenericTable } from "../../../../components/common/GenericTable/GenericTable";
import { useTranslation } from "react-i18next";
import { formikEmptyPropsGeneric } from "../../../../components/common/form/constant";
import {
  useCreateTruckFormatMutation,
  useGetTruckFormatQuery,
  useUpdateTruckFormatMutation
} from "../../../../services/truckFormat/truckFormat.service";
import { TruckFormatInitialValues, TruckFormatSchema } from "./schema";
import { TruckFormatForm } from "./components/TruckFormatForm";

interface TruckFormatProps {}

export const TruckFormat: FC<TruckFormatProps> = () => {
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");

  const {
    data: truckFormat,
    isLoading: truckFormatLoading
  } = useGetTruckFormatQuery();

  const [
    createTruckFormat,
    { isLoading: createLoading }
  ] = useCreateTruckFormatMutation();

  const [
    updateTruckFormat,
    { isLoading: updateLoading }
  ] = useUpdateTruckFormatMutation();

  if (truckFormatLoading) {
    return <LoaderComponent />;
  }

  return (
    <Page p={{ xs: 1, sm: 4 }}>
      <Box mb={1}>
        <MyBreadcrumbs start="back-office" />
      </Box>
      <GenericTable
        deactivate
        rowHeight={60}
        labels={{
          create: t("truckFormat.action.create"),
          update: t("truckFormat.action.edit")
        }}
        data={truckFormat!}
        create={{ query: createTruckFormat, createLoading }}
        update={{ query: updateTruckFormat, updateLoading }}
        columns={[
          {
            field: "id",
            title: t("truckFormat.title"),
            format: (value: string | number) => {
              const currentTruck = truckFormat?.find(
                (truck) => truck?.id === value
              );
              return `${currentTruck?.width} x ${currentTruck?.length}`;
            },
            sort(a, b) {
              return a.width * a.length - b.width * b.length;
            }
          },
          {
            field: "width",
            title: tCommon("width"),
            format: (value) => value + " mm"
          },
          {
            field: "length",
            title: tCommon("length"),
            format: (value) => value + " mm"
          },
          {
            field: "insideWidth",
            title: t("truckFormat.insideWidth"),
            format: (value) => value + " mm"
          },
          {
            field: "insideLength",
            title: t("truckFormat.insideLength"),
            format: (value) => value + " mm"
          },
          {
            field: "insideHeight",
            title: t("truckFormat.insideHeight"),
            format: (value) => value + " mm"
          },
          {
            field: "weight",
            title: tCommon("weight"),
            format: (value) => value + " kg"
          },
          {
            field: "height",
            title: tCommon("height"),
            format: (value) => value + " mm"
          },
          {
            field: "purchaseCost",
            title: t("truckFormat.truck-purchase-cost"),
            format: (value) => value + " €"
          },
          {
            field: "maxHeight",
            title: t("truckFormat.maxHeight"),
            format: (value) => value + " mm"
          },
          {
            field: "maxWeight",
            title: t("truckFormat.maxWeight"),
            format: (value) => value + " kg"
          },
          {
            field: "preferredUsageLevel",
            title: t("truckFormat.preferred-usage-level"),
            format: (value) => value
          }
        ]}
        schema={TruckFormatSchema}
        initialValues={TruckFormatInitialValues}
        genericForm={
          <TruckFormatForm
            {...formikEmptyPropsGeneric()}
            validateOnChange
            validateOnMount
          />
        }
      />
    </Page>
  );
};

export default TruckFormat;
