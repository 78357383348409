import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuListProps,
  OutlinedInput
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  emphasize,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
import { BaseTextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Omit } from "@material-ui/types";
import clsx from "clsx";
import { Field, FieldInputProps, FieldProps, useField } from "formik";
import { differenceWith, get, sortBy, unionBy } from "lodash";
import React, {
  CSSProperties,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ValueContainerProps } from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { Props as CreatableProps } from "react-select/src/Creatable";
import { FormatOptionLabelMeta, Props } from "react-select/src/Select";
import { ActionTypes, CommonProps, GroupType } from "react-select/src/types";
import { getLanguage } from "../../../reducers/app/selector";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { MyTooltip } from "../MyTooltip";
import { MyTypography } from "../MyTypography";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";
import { MyInputBox, MyInputBoxProps } from "./MyInputBox";

export interface OptionType {
  label: string;
  value: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
      minWidth: 290
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto"
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden"
    },
    chip: {
      margin: theme.spacing(1, 0.25)
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      )
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2)
    },
    singleValue: {
      fontSize: 16
    },
    placeholder: {
      position: "absolute",
      left: 14,
      fontSize: 16
    },
    paper: {
      position: "absolute",
      zIndex: 2,
      marginTop: -20,
      left: 0,
      right: 0
    },
    paperNoAbsolute: {
      borderRadius: "5px",
      marginTop: "3px",
      marginLeft: "-2%",
      zIndex: 2
    },
    divider: {
      height: theme.spacing(2)
    }
  })
);

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps,
    isFocused,
    isDisabled
  } = props;

  const { classes, TextFieldProps, isMulti, value } = selectProps;

  const finalValue =
    value && isMulti
      ? (value as any[]).length === 0
        ? ""
        : value
      : !value
      ? ""
      : value;

  const inputStyle = props?.selectProps?.styles?.control?.apply(
    undefined,
    {} as any
  );

  return (
    <MyFormControl
      {...TextFieldProps.formControlProps}
      {...TextFieldProps.myFormControlProps}
      formLabelProps={{
        ...TextFieldProps.myFormControlProps.formLabelProps,
        shrink: isFocused || !!finalValue
      }}
      disabled={isDisabled}
      isAnInputLabel
      data-testid={`my-form-control-${props.selectProps.name}`}
    >
      <OutlinedInput
        margin="dense"
        fullWidth
        inputComponent={inputComponent as any}
        notched={isFocused || !!finalValue}
        inputProps={{
          className: classes.input,
          ref: innerRef,
          style: { marginLeft: 10.5, ...inputStyle },
          children
        }}
        {...innerProps}
        labelWidth={TextFieldProps.labelWidth}
        value={finalValue}
        style={{ minHeight: 40 }}
      />
    </MyFormControl>
  );
}

// function Option(props: OptionProps<OptionType>) {
//   const { disabled } = props.data;
//   return (
//     <MenuItem
//       disabled={disabled}
//       ref={props.innerRef}
//       selected={props.isFocused}
//       component="div"
//       style={{
//         fontWeight: props.isSelected ? 500 : 400
//       }}
//       {...props.innerProps}
//     >
//       {props.children}
//     </MenuItem>
//   );
// }

const OptionListItem = (props: OptionProps<OptionType>) => {
  return (
    <ListItem
      button
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      disabled={props.data.disabled}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        cursor: props.data.disabled ? "not-allowed" : "initial"
      }}
      {...props.innerProps}
      data-testid={`my-autocomplete-field-item-${props.data.id}`}
    >
      <ListItemText> {props.children} </ListItemText>
      <ListItemSecondaryAction>
        <Grid container alignItems="center">
          <Grid item>
            {props.data.disabled &&
              get(
                props.selectProps,
                "OptionProps.optionDisabledSecondaryActionComponent"
              ) &&
              props.selectProps.OptionProps.optionDisabledSecondaryActionComponent(
                props.data
              )}
          </Grid>

          {props.data.helperText && (
            <Grid item>
              <MyTooltip
                title={<Typography>{props.data.helperText}</Typography>}
              />
            </Grid>
          )}
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, "innerProps"> &
  Partial<Pick<PlaceholderProps<OptionType>, "innerProps">>;
function Placeholder(props: MuiPlaceholderProps & any) {
  const { selectProps, isFocused, innerProps = {}, children } = props;
  return (
    <Typography
      style={{ visibility: isFocused ? "initial" : "hidden" }}
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  const valueContainerStyle = props?.selectProps?.styles?.valueContainer?.apply(
    undefined,
    {} as any
  );

  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ ...valueContainerStyle }}
    >
      {props.children}
    </div>
  );
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.isDisabled ? undefined : props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} style={{}} />}
    />
  );
}

function MenuSelect(props: MenuProps<OptionType>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      style={{
        top: props.menuPosition === "fixed" ? 24 : "inherit",
        left: props.menuPosition === "fixed" ? -10 : "inherit",
        width: "100%"
      }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const MenuList = (props: any, height: number) => {
  const menuListRef = useRef<any>(null);

  useEffect(() => {
    const checkIfListIsFullyVisible = () => {
      if (menuListRef.current) {
        const list = menuListRef.current.getBoundingClientRect();

        const isFullyVisible =
          list.bottom <= window.innerHeight && list.top >= 0;

        if (!isFullyVisible) {
          // Si la liste n'est pas entièrement visible, réduire la hauteur de 20px
          const maxHeight = window.innerHeight - list.top - 20; // 20px de marge
          menuListRef.current.style.maxHeight = `${maxHeight}px`;
        }
      }
    };
    checkIfListIsFullyVisible();
    window.addEventListener("resize", checkIfListIsFullyVisible);
    return () =>
      window.removeEventListener("resize", checkIfListIsFullyVisible);
  }, [menuListRef]);

  return (
    <div
      ref={menuListRef}
      style={{ maxHeight: `${height}px`, overflowY: "auto" }}
      {...props}
    >
      <List
        style={{
          overflowY: "auto"
        }}
        {...props}
      >
        {props.children}
      </List>
    </div>
  );
};

const formatGroupLabel: (group: GroupType<OptionType>) => React.ReactNode = ({
  label
}) => (
  <Typography
    color="primary"
    variant="body2"
    style={{ textTransform: "capitalize" }}
  >
    {label.toLowerCase()}
  </Typography>
);

const initialComponent = (withMenu: boolean, maxHeight: number) => {
  return {
    Control,
    ...(withMenu && { Menu: MenuSelect }),
    MultiValue,
    NoOptionsMessage,
    Option: OptionListItem,
    Placeholder,
    SingleValue,
    ValueContainer,
    MenuList: (props: any) => MenuList(props, maxHeight)
  };
};

export interface AutoCompleteOption extends OptionType {
  value: any;
  label: string;
  [k: string]: any;
}

type SelectProps = Props<OptionType> & CreatableProps<OptionType>;
export interface MyAutoCompleteFieldProps extends FormProps, SelectProps {
  options: AutoCompleteOption[];
  optionDisabledSecondaryActionComponent?: (
    currentOption: any
  ) => React.ReactNode;
  value?: any | any[];
  name: string;
  disabled?: boolean;
  groupBy?: string;
  creatable?: boolean;
  onClear?: () => null | undefined | [] | Record<string, never> | string;
  // TODO testing zindex for menuitems
  isAbsolute?: boolean;
  overrideFieldProps?: (field: FieldInputProps<any>) => FieldInputProps<any>;
  error?: string;
  inputBoxProps?: MyInputBoxProps;
  formatOptionLabel?:
    | ((
        option: OptionType,
        labelMeta: FormatOptionLabelMeta<OptionType>
      ) => React.ReactNode)
    | undefined;
}

export const AucocompleteComponentsBase = (
  props: CommonProps<OptionType> & MenuListProps
) => {
  const ref = useRef<any>();
  return (
    <List style={{ maxHeight: 400, overflowY: "auto" }} {...props} ref={ref}>
      {props.children}
    </List>
  );
};

export const MyAutocompleteField: React.FC<MyAutoCompleteFieldProps> = (
  props
) => {
  const {
    label,
    disabled,
    options,
    value: valueFromProps,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    groupBy,
    components,
    optionDisabledSecondaryActionComponent,
    creatable,
    onClear,
    isAbsolute,
    overrideFieldProps,
    myFormControlProps,
    error,
    inputBoxProps,
    formatOptionLabel,
    maxMenuHeight = 400,
    ...autoCompleteProps
  } = props;
  const [, { value: valueFromContext }] = useField(props.name);
  const value = valueFromProps ?? valueFromContext;
  const [selectValue, setSelectValue] = useState<
    OptionType | OptionType[] | null
  >();
  const classes = useStyles();
  const theme = useTheme();
  const previousValue = usePrevious(value);
  const [labelWidth, setLabelWidth] = useState(0);
  const [optionsStore, setOptionsStore] = useState<AutoCompleteOption[]>();
  const labelRef = useRef<HTMLLabelElement>(null);
  const lang = useSelector(getLanguage);
  const previousLang = usePrevious(lang);
  const { t } = useTranslation("common");
  const selectRef = useRef<any>();

  useEffect(() => {
    if (!!previousValue && value === undefined && selectRef.current) {
      selectRef.current.select.clearValue();
    }
  }, [previousValue, value]);

  useEffect(() => {
    labelRef.current && setLabelWidth(labelRef.current.offsetWidth);
  }, [props]);

  useEffect(() => {
    if (!options) {
      return;
    }
    if (!optionsStore || lang !== previousLang) {
      setOptionsStore(options);
    }
    if (
      optionsStore &&
      differenceWith(
        options.map((o) => o.value),
        optionsStore.map((o) => o.value)
      ).length > 0
    ) {
      setOptionsStore(unionBy(optionsStore, options, "value"));
    }
  }, [lang, options, optionsStore, previousLang]);

  useEffect(() => {
    if (value === undefined && selectValue) {
      setSelectValue(undefined);
    }
  }, [selectValue, value]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    if (!optionsStore) {
      return;
    }
    if (autoCompleteProps.isMulti && Array.isArray(value)) {
      setSelectValue(optionsStore.filter((op) => value.includes(op.value)));
    }
    if (!autoCompleteProps.isMulti) {
      setSelectValue(optionsStore.find((op) => op.value === value));
    }
  }, [autoCompleteProps.isMulti, optionsStore, previousValue, value]);

  // TODO To optimize because it should be based on action
  const onChange = (
    optionValue: OptionType | OptionType[],
    action: { action: ActionTypes; option: OptionType; name: string },
    onChange: (e: React.ChangeEvent<any>) => void
  ) => {
    if (action.action === "select-option") {
      if (autoCompleteProps.isMulti && action.option.disabled) {
        return;
      }
      if ((optionValue as AutoCompleteOption).disabled) {
        return;
      }
    }
    const e: any = { target: { value: undefined, name: props.name } };
    if (!optionValue) {
      if (autoCompleteProps.isMulti) {
        e.target.value = onClear ? onClear() : [];
      } else {
        e.target.value = onClear ? onClear() : undefined;
      }
      return onChange(e);
    }
    if (Array.isArray(optionValue)) {
      e.target.value = optionValue.map((v) => v.value);
      autoCompleteProps.isMulti && onChange(e);
    } else {
      e.target.value = optionValue.value;
      onChange(e);
    }
  };

  const finalOptions = useMemo(() => {
    if (groupBy) {
      return options.reduce((accumulateur, option) => {
        if (option[groupBy]) {
          const groupIndex = accumulateur.findIndex(
            (acc) => acc.label === option[groupBy]
          );
          if (groupIndex === -1) {
            return [
              ...accumulateur,
              { label: option[groupBy], options: [option] }
            ].sort((a, b) =>
              a?.label.localeCompare(b?.label, undefined, { numeric: true })
            );
          } else {
            accumulateur[groupIndex].options = sortBy(
              [...accumulateur[groupIndex].options, option],
              ["disabled", "label"]
            ).sort((a, b) =>
              a?.label.localeCompare(b?.label, undefined, { numeric: true })
            );
            return accumulateur;
          }
        }
        return accumulateur;
      }, [] as ReadonlyArray<GroupType<AutoCompleteOption>>);
    }

    return options;
  }, [groupBy, options]);

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    }),
    ...autoCompleteProps.styles
  };

  return (
    <MyInputBox {...inputBoxProps} fullWidth>
      <Field name={props.name} style={{ maxHeight: 300 }}>
        {({ field: originalField, form, meta }: FieldProps<any>) => {
          const field = overrideFieldProps
            ? overrideFieldProps(originalField)
            : originalField;
          const errorField = get(form.errors, field.name);
          const touchField = get(form.touched, field.name);
          const myFormControlProps: MyFormControlProps = {
            disabled,
            errorField: error ? error : errorField,
            touchField,
            label,
            labelRef,
            helperText,
            formLabelProps: {
              ...formLabelProps,
              required: autoCompleteProps.required
            },
            formHelperTextProps
          };

          return creatable ? (
            <CreatableSelect
              isDisabled={disabled}
              formatCreateLabel={(inputValue) => (
                <MyTypography leftIcon={<AddIcon color="primary" />}>{`${t(
                  "create"
                )} " ${inputValue} "`}</MyTypography>
              )}
              createOptionPosition="last"
              className="autocomplete"
              closeMenuOnSelect={!autoCompleteProps.isMulti}
              components={{
                ...initialComponent(
                  autoCompleteProps.menuPlacement !== "top",
                  maxMenuHeight
                ),
                ...components
              }}
              formatGroupLabel={formatGroupLabel}
              backspaceRemovesValue
              placeholder={null}
              {...autoCompleteProps}
              value={selectValue}
              classes={classes}
              styles={selectStyles}
              inputId={field.name}
              onBlur={field.onBlur}
              OptionProps={{
                optionDisabledSecondaryActionComponent
              }}
              TextFieldProps={{
                labelWidth,
                formControlProps,
                myFormControlProps
              }}
              options={finalOptions}
              onChange={(value: any, action: any) =>
                onChange(value, action, field.onChange)
              }
            />
          ) : (
            <Select
              menuPlacement="auto"
              clearFocusValueOnUpdate
              ref={selectRef}
              isDisabled={disabled}
              className="autocomplete"
              closeMenuOnSelect={!autoCompleteProps.isMulti}
              components={{
                ...initialComponent(
                  autoCompleteProps.menuPlacement !== "top",
                  maxMenuHeight
                ),
                ...components
                /*    MenuList: (props: any) => {
                  const MenuListComponent =
                    components?.MenuList ??
                    ((props: any) => MenuList(props, maxMenuHeight));
                  return <MenuListComponent {...props} />;
                } */
              }}
              formatGroupLabel={formatGroupLabel}
              formatOptionLabel={formatOptionLabel}
              placeholder={null}
              {...autoCompleteProps}
              value={selectValue}
              classes={{
                ...classes,
                paper: isAbsolute ? classes.paperNoAbsolute : classes.paper
              }}
              inputId={field.name}
              onBlur={field.onBlur}
              OptionProps={{
                optionDisabledSecondaryActionComponent
              }}
              TextFieldProps={{
                labelWidth,
                formControlProps,
                myFormControlProps
              }}
              options={finalOptions}
              onChange={(value: any, action: any) => {
                onChange(value, action, field.onChange);
                if (!touchField) {
                  form.setFieldTouched(field.name, true);
                }
              }}
              menuPortalTarget={isAbsolute ? document.body : null}
              styles={{
                ...selectStyles,
                ...(selectRef.current &&
                  isAbsolute && {
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999
                    })
                  }),
                menu: (base) => {
                  return {
                    ...base,
                    marginLeft: "-3%"
                  };
                }
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              /* menuIsOpen
                menuShouldBlockScroll
              defaultMenuIsOpen
              autoFocus
              menuPlacement={"top"}
                menuPosition={"fixed"} 
               menuPortalTarget={isAbsolute ? selectRef?.current?.target : null} */
            />
          );
        }}
      </Field>
    </MyInputBox>
  );
};
