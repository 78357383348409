import produce from "immer";
import { merge } from "lodash";
import forEach from "lodash/forEach";
import { MyExpansionPanelStatus } from "../../components/common/MyExpansionPanel";
import { SideName } from "../../entities/brief";
import {
  PrintMethodName,
  StampingMethodName,
  VarnishMethodName
} from "../../entities/decoration";
import { EmbossingDebossing } from "../../entities/embossingDebossing";
import { HotStamping } from "../../entities/hotstamping";
import { InkParameterDTO } from "../../entities/inkParameter";
import { Intercom } from "../../entities/intercom";
import { luxuryVarnishParameterDTO } from "../../entities/luxuryVarnishParameter";
import { ProductCategory } from "../../entities/productCategory";
import { QuantityCount } from "../../entities/quantityCount";
import { VarnishParameterDTO } from "../../entities/varnishParameter";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import {
  ConfiguratorInputOptionsComputed,
  InputUUID
} from "../configurator-input-options/selector";
import {
  ConfiguratorInputNames,
  SectionNames
} from "../configurator-inputs/constant";
import { Product } from "../products/entity";
import { Scale3D } from "./../products/entity";
import {
  BriefElementConfiguratorActions,
  BriefElementConfiguratorActionsTypes
} from "./action";
import {
  BriefElementConfiguratorInitialState,
  BriefElementStateInitial
} from "./constant";
import { LayoutCount } from "./types";
import { ConfiguratorInputOption } from "../configurator-input-options/entity";

export interface SectionState {
  status?: MyExpansionPanelStatus;
  touched?: boolean;
  errors?: string | { [k: string]: string };
  isExpanded: boolean;
  dependentProperties: ConfiguratorInputNames[];
}
export type SectionsState = {
  [key in SectionNames]: SectionState;
};

export interface BriefElementConfiguratorValues {
  id?: string;
  briefId?: string;
  position?: number;
  productCategoryId?: string;
  productId?: string;
  lengthDimension?: number;
  widthDimension?: number;
  heightDimension?: number;
  widthGluedFlap?: number;
  widthInsideFlap?: number;
  specifyRequest?: string;
  totalRuleLength?: number;
  distanceBetweenLayoutHeight?: number;
  distanceBetweenLayoutWidth?: number;
  bagPieces?: number;
  dieCutToolExist?: boolean;
  dieCutToolLayoutCount?: LayoutCount[];
  dieCutToolForce?: boolean;
  dieCutToolMachineIds?: string[];
  addWindow?: boolean;
  windowWidth?: number;
  windowHeight?: number;
  windowWantProtection?: boolean;
  windowId?: string;
  specifyWindow?: string;
  matterId?: string;
  materialTypeId?: string;
  grammage?: number;
  materialReferenceId?: string;
  materialReferenceName?: string;
  cardboardVersoPosition?: boolean;
  wantDecoration?: boolean;
  referencesCount?: number;
  sidePrinted?: SideName;
  wantOffsetPrinting?: boolean;
  versoColorTypeCMYK?: boolean;
  versoDirectToneColorType?: boolean;
  versoDirectToneColorCount?: number;
  versoColorCount?: number;
  versoColorQuality?: string;
  versoInkPercentage?: number;
  rectoColorTypeCMYK?: boolean;
  rectoDirectToneColorType?: boolean;
  rectoDirectToneColorCount?: number;
  rectoColorCount?: number;
  rectoColorQuality?: string;
  rectoInkPercentage?: number;
  additionalInkScreenPrinting?: boolean;
  additionalInkScreenPrintingColorCount?: number;
  additionalInkScreenPrintingPercentage?: number;
  needStamping?: boolean;
  stampingMethod?: string;
  polyesterLaminationId?: string;
  hotstampings?: HotStamping[];
  protectionIds?: string[];
  wantLuxury?: boolean;
  laminationId?: string;
  basicVarnishId?: string;
  luxuryVarnishId?: string;
  wantEmbossingDebossing?: boolean;
  embossingDebossings?: EmbossingDebossing[];
  decorationsCount?: QuantityCount[];
  intercom?: Intercom;
  deliveryZipCode?: string;
  name?: string;
  addMecanisation?: boolean;
  addTearStrip?: boolean;
  addSealingStrip?: boolean;
  sideVariationPrint?: string;
  printMethodRecto?: PrintMethodName;
  printMethodVerso?: PrintMethodName;

  // Finishes
  wantProtection?: boolean;
  sideProtected?: SideName;
  wantVarnishRecto?: boolean;
  wantVarnishVerso?: boolean;
  varnishMethodRecto?: VarnishMethodName;
  varnishMethodVerso?: VarnishMethodName;
  flexoVarnishRectoId?: string;
  flexoVarnishVersoId?: string;
  flexoVarnishCoverageRecto?: number;
  flexoVarnishCoverageVerso?: number;
  finishPartialSurfaceRecto?: boolean;
  finishPartialSurfaceVerso?: boolean;
  flexoPlateVarnishExistRecto?: boolean;
  flexoPlateVarnishExistVerso?: boolean;
  flexoPlateVarnishForceRecto?: boolean;
  flexoPlateVarnishForceVerso?: boolean;
  flexoPlateVarnishLayoutCountRecto?: LayoutCount[];
  flexoPlateVarnishLayoutCountVerso?: LayoutCount[];
  varnishParamsOffsetRecto?: VarnishParameterDTO[];
  varnishParamsOffsetVerso?: VarnishParameterDTO[];
  wantLaminationRecto?: boolean;
  wantLaminationVerso?: boolean;
  laminationIdRecto?: string;
  laminationIdVerso?: string;
  wantFinishes?: boolean;
  sideFinished?: SideName;
  wantLuxuryVarnishRecto?: boolean;
  wantLuxuryVarnishVerso?: boolean;
  luxuryVarnishParamsRecto?: luxuryVarnishParameterDTO[];
  luxuryVarnishParamsVerso?: luxuryVarnishParameterDTO[];
  wantEmbossingDebossingRecto?: boolean;
  wantEmbossingDebossingVerso?: boolean;
  embossingDebossingParamsRecto?: EmbossingDebossing[];
  embossingDebossingParamsVerso?: EmbossingDebossing[];
  // end Finishes

  wantPrintOnEdgeRecto?: boolean;
  wantPrintOnEdgeVerso?: boolean;
  wantFullSurfaceRecto?: boolean;
  wantFullSurfaceVerso?: boolean;
  wantPrintOnCreasingRecto?: boolean;
  wantPrintOnCreasingVerso?: boolean;
  wantTechnicalPrintingRecto?: boolean;
  wantTechnicalPrintingVerso?: boolean;
  cmykParamsRecto?: InkParameterDTO[];
  cmykParamsVerso?: InkParameterDTO[];
  directToneParamsRecto?: InkParameterDTO[];
  directToneParamsVerso?: InkParameterDTO[];
  wantDecorAmalgam?: boolean;
  materialFluteId?: string;
  materialQuality?: number | null;
  materialLinerRectoId?: string;
  materialLinerVersoId?: string;
  corrugatedMaterialId?: string;
  corrugatedMaterialCompositionIds?: string[];
  dividersLengthBigElement?: number;
  dividersLengthSmallElement?: number;
  dividersHeightElement?: number;
  dividersNotchBorderDistance?: number;
  dividersSpaceBetweenNotchesBigElement?: number;
  dividersSpaceBetweenNotchesSmallElement?: number;
  flexoPlateInkExistVerso?: boolean;
  flexoPlateInkForceVerso?: boolean;
  flexoPlateInkLayoutCountVerso?: LayoutCount[];
  flexoPlateInkExistRecto?: boolean;
  flexoPlateInkForceRecto?: boolean;
  flexoPlateInkLayoutCountRecto?: LayoutCount[];
  external?: Record<string, number>;
  subCategories?: DynamicJsonTranslator[];
  subCategoriesIds?: string[];
  digitalPrintingModeRectoId?: string;
  digitalPrintingModeVersoId?: string;
  digitalPrintingTypeRectoId?: string;
  digitalPrintingTypeVersoId?: string;
  cmykwParamsDigitalRecto?: InkParameterDTO[];
  cmykwParamsDigitalVerso?: InkParameterDTO[];
  versoColorTypeCMYKOffset?: boolean;
  versoDirectToneColorTypeOffset?: boolean;
  rectoColorTypeCMYKOffset?: boolean;
  rectoDirectToneColorTypeOffset?: boolean;
  cmykParamsOffsetRecto?: InkParameterDTO[];
  cmykParamsOffsetVerso?: InkParameterDTO[];
  directToneParamsOffsetRecto?: InkParameterDTO[];
  directToneParamsOffsetVerso?: InkParameterDTO[];
  screenPrintingRecto?: boolean;
  screenPrintingVerso?: boolean;
  directToneParamsScreenPrintingRecto?: InkParameterDTO[];
  directToneParamsScreenPrintingVerso?: InkParameterDTO[];
  wantStampingRecto?: boolean;
  stampingMethodRecto?: StampingMethodName;
  hotstampingParamsRecto?: HotStamping;
  polyesterLaminationIdRecto?: string;
  wantStampingVerso?: boolean;
  stampingMethodVerso?: StampingMethodName;
  hotstampingParamsVerso?: HotStamping;
  polyesterLaminationIdVerso?: string;
  inkDigitalModeRectoId?: string;
  inkDigitalModeVersoId?: string;
  inkDigitalTypeRectoId?: string;
  inkDigitalTypeVersoId?: string;
  lithoLaminationMaterialReferenceRectoId?: string;
  lithoLaminationMaterialReferenceVersoId?: string;
  lithoLaminationExtraBorderRecto?: number;
  lithoLaminationExtraBorderVerso?: number;
}

export type BriefElementPreconditions = Record<InputUUID, boolean>;

export type BriefElementInterInputOptionsActivated = Record<
  InputUUID,
  ConfiguratorInputOption
>;

export type BriefElementInterInputOptionsComputed = Map<
  InputUUID,
  ConfiguratorInputOption[]
>;

export interface BriefElementState {
  _key: number;
  submitValues: BriefElementConfiguratorValues;
  productSelected?: Product;
  productCategorySelected?: ProductCategory;
  initialValues: BriefElementConfiguratorValues;
  values: BriefElementConfiguratorValues;
  submitCount: number;
  hasSubmitOnce: boolean;
  isValid: boolean;
  valuesHasChangedAfterSubmit: boolean;
  sectionsState: SectionsState;
  blur: boolean;
  scale3D?: Scale3D;
  productVisibilityState?: boolean;
  productFromUrl?: boolean;
  inputOptionsComputed: ConfiguratorInputOptionsComputed;
}

export interface BriefElementConfiguratorState {
  [index: string]: BriefElementState | undefined;
}

export const BriefElementConfiguratorReducer = (
  state = BriefElementConfiguratorInitialState,
  action: BriefElementConfiguratorActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BriefElementConfiguratorActionsTypes.SET_SECTION_TOUCHED:
        draft[action.position]!.sectionsState.PACKAGING_TYPE.touched = true;
        draft[action.position]!.sectionsState.MATERIALS.touched = true;
        draft[action.position]!.sectionsState.DECORATIONS.touched = true;
        draft[action.position]!.sectionsState.FINISHES.touched = true;
        draft[action.position]!.sectionsState.PURCHASE_CONDITION.touched = true;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SECTION_TOUCHED:
        if (
          !state[action.position]?.sectionsState[action.sectionName].touched &&
          !!draft[action.position]
        )
          draft[action.position]!.sectionsState[
            action.sectionName
          ].touched = true;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SECTION_EXPAND:
        if (draft[action.position]) {
          if (action.closeOthers) {
            forEach<SectionsState>(
              draft[action.position]!.sectionsState,
              (section) => {
                section.isExpanded = false;
              }
            );
          }
          draft[action.position]!.sectionsState[action.sectionName].isExpanded =
            action.isExpanded;
        }
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED:
        if (draft[action.position]) {
          draft[action.position]!.productSelected = action.productSelected;
        }
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED_SCALE:
        if (draft[action.position])
          draft[action.position]!.scale3D = action.scale3D;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_VISIBILITY_STATE:
        if (draft[action.position])
          draft[action.position]!.productVisibilityState =
            action.productVisibilityState;
        break;

      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_FROM_URL:
        if (draft[action.position])
          draft[action.position]!.productFromUrl = action.productFromUrl;
        break;

      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_CATEGORY_SELECTED:
        if (draft[action.position])
          draft[action.position]!.productCategorySelected =
            action.productCategorySelected;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_VALUES:
        if (draft[action.position]) {
          draft[action.position]!.values =
            action.values || state[action.position]!.values;
          draft[action.position]!._key = new Date().getTime();
        } else {
          draft[action.position] = {
            ...BriefElementStateInitial,
            values: action.values || BriefElementStateInitial.values
          };
        }
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_INITIAL_VALUES:
        if (draft[action.position]) {
          draft[action.position]!.initialValues =
            action.initialValues || state[action.position]!.initialValues;
          draft[action.position]!._key = new Date().getTime();
        } else
          draft[action.position] = {
            ...BriefElementStateInitial,
            initialValues:
              action.initialValues || BriefElementStateInitial.initialValues
          };
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_VALUES:
        if (draft[action.position])
          draft[action.position]!.submitValues = action.submitValues;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ON_CHANGE:
        // Call on unmount so check if this element is not deleted
        if (draft[action.position])
          draft[action.position]!.values = action.values;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_COUNT:
        if (draft[action.position])
          draft[action.position]!.submitCount = action.count;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_HAS_SUBMIT_ONCE:
        if (draft[action.position])
          draft[action.position]!.hasSubmitOnce = true;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT:
        if (draft[action.position])
          draft[action.position]!.valuesHasChangedAfterSubmit =
            action.hasChange;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_IS_VALID:
        // Call on unmount so check if this element is not deleted
        if (draft[action.position])
          draft[action.position]!.isValid = action.isValid;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_CLEAR:
        return BriefElementConfiguratorInitialState;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_CLEAR_AND_SWITCH_TO_PRODUCT:
        draft[action.position] = merge({}, BriefElementStateInitial, {
          // Update the key to have a clean component refresh
          _key: new Date().getTime(),
          sectionsState: {
            [SectionNames.PACKAGING_TYPE]: { isExpanded: true },
            [SectionNames.MATERIALS]: { isExpanded: false },
            [SectionNames.DECORATIONS]: { isExpanded: false },
            [SectionNames.FINISHES]: { isExpanded: false },
            [SectionNames.PURCHASE_CONDITION]: { isExpanded: false }
          },
          initialValues: {
            productId: action.product.id,
            productCategoryId: action.productCategory.id
          },
          values: {
            productId: action.product.id,
            productCategoryId: action.productCategory.id
          }
        });
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ADD:
        draft[action.nextPosition] = {
          ...BriefElementStateInitial,
          _key: new Date().getTime()
        };
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_REMOVE:
        if (Object.keys(draft).length <= 1) {
          return BriefElementConfiguratorInitialState;
        }
        delete draft[action.position];
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_BLUR:
        if (draft[action.position]) draft[action.position]!.blur = true;
        break;
      case BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_INPUT_OPTIONS_COMPUTED:
        if (draft[action.position]) {
          draft[action.position]!.inputOptionsComputed =
            action.inputOptionsComputed;
        }
        break;
      default:
        return draft;
    }
  });
