import { ConfiguratorInputNames } from "../configurator-inputs/constant";
import {
  BriefElementConfiguratorValues,
  BriefElementPreconditions,
  BriefElementConfiguratorState,
  BriefElementState
} from "./reducer";

export const BriefElementConfiguratorInitialValues: BriefElementConfiguratorValues = {
  position: undefined,
  productId: undefined,
  productCategoryId: undefined,
  lengthDimension: undefined,
  widthDimension: undefined,
  heightDimension: undefined,
  widthGluedFlap: undefined,
  widthInsideFlap: undefined,
  specifyRequest: undefined,
  totalRuleLength: undefined,
  distanceBetweenLayoutHeight: undefined,
  distanceBetweenLayoutWidth: undefined,
  bagPieces: undefined,
  dieCutToolExist: false,
  dieCutToolLayoutCount: undefined,
  dieCutToolForce: undefined,
  dieCutToolMachineIds: undefined,
  addWindow: undefined,
  windowWidth: undefined,
  windowHeight: undefined,
  windowWantProtection: undefined,
  windowId: undefined,
  specifyWindow: undefined,
  matterId: undefined,
  grammage: undefined,
  materialReferenceId: undefined,
  materialReferenceName: undefined,
  cardboardVersoPosition: false,
  materialTypeId: undefined,
  wantDecoration: false,
  referencesCount: 0,
  sidePrinted: undefined,
  wantOffsetPrinting: false,
  versoColorTypeCMYK: undefined,
  versoDirectToneColorType: undefined,
  versoDirectToneColorCount: undefined,
  versoColorCount: undefined,
  versoColorQuality: undefined,
  versoInkPercentage: undefined,
  rectoColorTypeCMYK: undefined,
  rectoDirectToneColorType: undefined,
  rectoDirectToneColorCount: undefined,
  rectoColorCount: undefined,
  rectoColorQuality: undefined,
  rectoInkPercentage: undefined,
  additionalInkScreenPrinting: undefined,
  additionalInkScreenPrintingColorCount: undefined,
  additionalInkScreenPrintingPercentage: undefined,
  needStamping: false,
  stampingMethod: undefined,
  polyesterLaminationId: undefined,
  hotstampings: undefined,
  protectionIds: undefined,
  wantLuxury: false,
  laminationId: undefined,
  basicVarnishId: undefined,
  luxuryVarnishId: undefined,
  wantEmbossingDebossing: false,
  embossingDebossings: undefined,
  decorationsCount: [{ quantity: undefined, position: 0 }],
  name: undefined,
  addMecanisation: false,
  addTearStrip: false,
  addSealingStrip: false,
  sideVariationPrint: undefined,
  printMethodRecto: undefined,
  printMethodVerso: undefined,

  // Finishes
  wantProtection: false,
  sideProtected: undefined,
  wantVarnishRecto: false,
  wantVarnishVerso: false,
  varnishMethodRecto: undefined,
  varnishMethodVerso: undefined,
  flexoVarnishRectoId: undefined,
  flexoVarnishVersoId: undefined,
  flexoVarnishCoverageRecto: undefined,
  flexoVarnishCoverageVerso: undefined,
  finishPartialSurfaceRecto: false,
  finishPartialSurfaceVerso: false,
  flexoPlateVarnishExistRecto: false,
  flexoPlateVarnishExistVerso: undefined,
  flexoPlateVarnishForceRecto: false,
  flexoPlateVarnishForceVerso: undefined,
  flexoPlateVarnishLayoutCountRecto: undefined,
  flexoPlateVarnishLayoutCountVerso: undefined,
  varnishParamsOffsetRecto: undefined,
  varnishParamsOffsetVerso: undefined,
  wantLaminationRecto: false,
  wantLaminationVerso: false,
  laminationIdRecto: undefined,
  laminationIdVerso: undefined,
  wantFinishes: false,
  sideFinished: undefined,
  wantLuxuryVarnishRecto: false,
  wantLuxuryVarnishVerso: false,
  luxuryVarnishParamsRecto: undefined,
  luxuryVarnishParamsVerso: undefined,
  wantEmbossingDebossingRecto: false,
  wantEmbossingDebossingVerso: false,
  embossingDebossingParamsRecto: undefined,
  embossingDebossingParamsVerso: undefined,
  // End Finishes

  wantPrintOnEdgeRecto: false,
  wantPrintOnEdgeVerso: false,
  wantFullSurfaceRecto: false,
  wantFullSurfaceVerso: false,
  wantPrintOnCreasingRecto: false,
  wantPrintOnCreasingVerso: false,
  wantTechnicalPrintingRecto: false,
  wantTechnicalPrintingVerso: false,
  cmykParamsRecto: undefined,
  cmykParamsVerso: undefined,
  directToneParamsRecto: undefined,
  directToneParamsVerso: undefined,
  wantDecorAmalgam: false,
  materialFluteId: undefined,
  materialQuality: undefined,
  materialLinerRectoId: undefined,
  materialLinerVersoId: undefined,
  corrugatedMaterialId: undefined,
  corrugatedMaterialCompositionIds: undefined,
  dividersLengthBigElement: undefined,
  dividersLengthSmallElement: undefined,
  dividersHeightElement: undefined,
  dividersNotchBorderDistance: undefined,
  dividersSpaceBetweenNotchesBigElement: undefined,
  dividersSpaceBetweenNotchesSmallElement: undefined,
  flexoPlateInkExistVerso: undefined,
  flexoPlateInkForceVerso: undefined,
  flexoPlateInkLayoutCountVerso: undefined,
  flexoPlateInkExistRecto: undefined,
  flexoPlateInkForceRecto: undefined,
  flexoPlateInkLayoutCountRecto: undefined,
  external: {},
  subCategories: [],
  subCategoriesIds: [],
  digitalPrintingModeRectoId: undefined,
  digitalPrintingModeVersoId: undefined,
  digitalPrintingTypeRectoId: undefined,
  digitalPrintingTypeVersoId: undefined,
  cmykwParamsDigitalRecto: undefined,
  cmykwParamsDigitalVerso: undefined,
  versoColorTypeCMYKOffset: undefined,
  versoDirectToneColorTypeOffset: undefined,
  rectoColorTypeCMYKOffset: undefined,
  rectoDirectToneColorTypeOffset: undefined,
  cmykParamsOffsetRecto: undefined,
  cmykParamsOffsetVerso: undefined,
  directToneParamsOffsetRecto: undefined,
  directToneParamsOffsetVerso: undefined,
  screenPrintingRecto: undefined,
  screenPrintingVerso: undefined,
  directToneParamsScreenPrintingRecto: undefined,
  directToneParamsScreenPrintingVerso: undefined,
  wantStampingRecto: false,
  stampingMethodRecto: undefined,
  hotstampingParamsRecto: undefined,
  polyesterLaminationIdRecto: undefined,
  wantStampingVerso: false,
  stampingMethodVerso: undefined,
  hotstampingParamsVerso: undefined,
  polyesterLaminationIdVerso: undefined,
  inkDigitalModeRectoId: undefined,
  inkDigitalModeVersoId: undefined,
  inkDigitalTypeRectoId: undefined,
  inkDigitalTypeVersoId: undefined,
  lithoLaminationMaterialReferenceRectoId: undefined,
  lithoLaminationMaterialReferenceVersoId: undefined,
  lithoLaminationExtraBorderRecto: undefined,
  lithoLaminationExtraBorderVerso: undefined
};

export const BriefElementPreconditionsInitial: BriefElementPreconditions = {
  ...Object.keys(BriefElementConfiguratorInitialValues).reduce(
    (acc, curr) => ({ ...acc, [curr]: false }),
    {} as BriefElementPreconditions
  ),
  productCategoryId: true,
  productId: true
};

const PackagingTypeProperties: ConfiguratorInputNames[] = [
  ConfiguratorInputNames.PRODUCT_ID,
  ConfiguratorInputNames.SPECIFY_REQUEST,
  ConfiguratorInputNames.WINDOW_WANT_PROTECTION,
  ConfiguratorInputNames.BAG_PIECES,
  ConfiguratorInputNames.PRODUCT_CATEGORY_ID,
  ConfiguratorInputNames.WIDTH_DIMENSION,
  ConfiguratorInputNames.HEIGHT_DIMENSION,
  ConfiguratorInputNames.LENGTH_DIMENSION,
  ConfiguratorInputNames.WIDTH_GLUED_FLAP,
  ConfiguratorInputNames.WIDTH_INSIDE_FLAP,
  ConfiguratorInputNames.TOTAL_RULE_LENGTH,
  ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_HEIGHT,
  ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_WIDTH,
  ConfiguratorInputNames.ADD_WINDOW,
  ConfiguratorInputNames.WINDOW_HEIGHT,
  ConfiguratorInputNames.WINDOW_WIDTH,
  ConfiguratorInputNames.WINDOW_ID,
  ConfiguratorInputNames.SPECIFY_WINDOW,
  ConfiguratorInputNames.ADD_SEALING_STRIP,
  ConfiguratorInputNames.ADD_TEAR_STRIP,
  ConfiguratorInputNames.ADD_MECANISATION,
  ConfiguratorInputNames.DIVIDERS_LENGTH_BIG_ELEMENT,
  ConfiguratorInputNames.DIVIDERS_LENGTH_SMALL_ELEMENT,
  ConfiguratorInputNames.DIVIDERS_HEIGHT_ELEMENT,
  ConfiguratorInputNames.DIVIDERS_NOTCH_BORDER_DISTANCE,
  ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT,
  ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT,
  ConfiguratorInputNames.DIECUT_TOOL_EXIST,
  ConfiguratorInputNames.DIECUT_TOOL_LAYOUT_COUNT,
  ConfiguratorInputNames.DIECUT_TOOL_FORCE,
  ConfiguratorInputNames.DIECUT_TOOL_MACHINE_IDS
];

const MaterialsProperties: ConfiguratorInputNames[] = [
  ConfiguratorInputNames.MATTER_ID,
  ConfiguratorInputNames.MATERIAL_TYPE_ID,
  ConfiguratorInputNames.MATERIAL_REFERENCE_ID,
  ConfiguratorInputNames.MATERIAL_REFERENCE_NAME,
  ConfiguratorInputNames.CARDBOARD_VERSO_POSITION,
  ConfiguratorInputNames.GRAMMAGE,
  ConfiguratorInputNames.MATERIAL_FLUTE_ID,
  ConfiguratorInputNames.MATERIAL_QUALITY,
  ConfiguratorInputNames.MATERIAL_LINER_RECTO_ID,
  ConfiguratorInputNames.MATERIAL_LINER_VERSO_ID,
  ConfiguratorInputNames.CORRUGATED_MATERIAL_ID,
  ConfiguratorInputNames.CORRUGATED_MATERIAL_COMPOSITION_IDS
];
export const DecorationsProperties: ConfiguratorInputNames[] = [
  ConfiguratorInputNames.WANT_DECORATION,
  ConfiguratorInputNames.NEED_STAMPING,
  ConfiguratorInputNames.REFERENCES_COUNT,
  ConfiguratorInputNames.SIDE_PRINTED,
  ConfiguratorInputNames.RECTO_COLOR_COUNT,
  ConfiguratorInputNames.RECTO_COLOR_QUALITY,
  ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK,
  ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_COUNT,
  ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE,
  ConfiguratorInputNames.RECTO_INK_PERCENTAGE,
  ConfiguratorInputNames.VERSO_COLOR_COUNT,
  ConfiguratorInputNames.VERSO_COLOR_QUALITY,
  ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK,
  ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_COUNT,
  ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE,
  ConfiguratorInputNames.VERSO_INK_PERCENTAGE,
  ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING,
  ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING_COLOR_COUNT,
  ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING_PERCENTAGE,
  ConfiguratorInputNames.STAMPING_METHOD,
  ConfiguratorInputNames.HOTSTAMPINGS,
  ConfiguratorInputNames.POLYESTER_LAMINATION_ID,
  ConfiguratorInputNames.SIDE_VARIATION_PRINT,
  ConfiguratorInputNames.PRINT_METHOD_RECTO,
  ConfiguratorInputNames.PRINT_METHOD_VERSO,
  ConfiguratorInputNames.WANT_PRINT_ON_EDGE_RECTO,
  ConfiguratorInputNames.WANT_PRINT_ON_EDGE_VERSO,
  ConfiguratorInputNames.WANT_FULL_SURFACE_RECTO,
  ConfiguratorInputNames.WANT_FULL_SURFACE_VERSO,
  ConfiguratorInputNames.WANT_PRINT_ON_CREASING_RECTO,
  ConfiguratorInputNames.WANT_PRINT_ON_CREASING_VERSO,
  ConfiguratorInputNames.CMYK_PARAMS_RECTO,
  ConfiguratorInputNames.CMYK_PARAMS_VERSO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_RECTO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_VERSO,
  ConfiguratorInputNames.WANT_DECOR_AMALGAM,
  ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_RECTO,
  ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_RECTO,
  ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_RECTO,
  ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK_OFFSET,
  ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE_OFFSET,
  ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK_OFFSET,
  ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE_OFFSET,
  ConfiguratorInputNames.WANT_OFFSET_PRINTING,
  ConfiguratorInputNames.CMYK_PARAMS_OFFSET_RECTO,
  ConfiguratorInputNames.CMYK_PARAMS_OFFSET_VERSO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_RECTO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_VERSO,
  ConfiguratorInputNames.SCREENPRINTING_RECTO,
  ConfiguratorInputNames.SCREENPRINTING_VERSO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_RECTO,
  ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_VERSO,
  ConfiguratorInputNames.WANT_STAMPING_RECTO,
  ConfiguratorInputNames.WANT_STAMPING_VERSO,
  ConfiguratorInputNames.STAMPING_METHOD_RECTO,
  ConfiguratorInputNames.STAMPING_METHOD_VERSO,
  ConfiguratorInputNames.HOTSTAMPING_PARAMS_RECTO,
  ConfiguratorInputNames.HOTSTAMPING_PARAMS_VERSO,
  ConfiguratorInputNames.POLYESTER_LAMINATION_ID_RECTO,
  ConfiguratorInputNames.POLYESTER_LAMINATION_ID_VERSO,
  ConfiguratorInputNames.DIGITAL_PRINTING_MODE_RECTO_ID,
  ConfiguratorInputNames.DIGITAL_PRINTING_MODE_VERSO_ID,
  ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_RECTO_ID,
  ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_VERSO_ID,
  ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_RECTO,
  ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_VERSO,
  ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_RECTO_ID,
  ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_VERSO_ID,
  ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_RECTO,
  ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_VERSO
];
export const FinishesProperties: ConfiguratorInputNames[] = [
  ConfiguratorInputNames.WANT_PROTECTION,
  ConfiguratorInputNames.SIDE_PROTECTED,
  ConfiguratorInputNames.WANT_VARNISH_RECTO,
  ConfiguratorInputNames.WANT_VARNISH_VERSO,
  ConfiguratorInputNames.VARNISH_METHOD_RECTO,
  ConfiguratorInputNames.VARNISH_METHOD_VERSO,
  ConfiguratorInputNames.FLEXO_VARNISH_RECTO_ID,
  ConfiguratorInputNames.FLEXO_VARNISH_VERSO_ID,
  ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_RECTO,
  ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_VERSO,
  ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_RECTO,
  ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_RECTO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_RECTO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_VERSO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_RECTO,
  ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_VERSO,
  ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_RECTO,
  ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_VERSO,
  ConfiguratorInputNames.WANT_LAMINATION_RECTO,
  ConfiguratorInputNames.WANT_LAMINATION_VERSO,
  ConfiguratorInputNames.LAMINATION_ID_RECTO,
  ConfiguratorInputNames.LAMINATION_ID_VERSO,
  ConfiguratorInputNames.WANT_FINISHES,
  ConfiguratorInputNames.SIDE_FINISHED,
  ConfiguratorInputNames.WANT_LUXURY_VARNISH_RECTO,
  ConfiguratorInputNames.WANT_LUXURY_VARNISH_VERSO,
  ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_RECTO,
  ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_VERSO,
  ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_RECTO,
  ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_VERSO,
  ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_RECTO,
  ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_VERSO
];
const PurchaseConditionProperties: ConfiguratorInputNames[] = [
  ConfiguratorInputNames.DECORATIONS_COUNT
];

export const BriefElementStateInitial: BriefElementState = {
  _key: new Date().getTime(),
  submitValues: BriefElementConfiguratorInitialValues,
  isValid: false,
  productSelected: undefined,
  productCategorySelected: undefined,
  values: BriefElementConfiguratorInitialValues,
  initialValues: BriefElementConfiguratorInitialValues,
  submitCount: 0,
  hasSubmitOnce: false,
  valuesHasChangedAfterSubmit: false,
  blur: false,
  sectionsState: {
    PACKAGING_TYPE: {
      isExpanded: false,
      dependentProperties: PackagingTypeProperties
    },
    MATERIALS: {
      isExpanded: false,
      dependentProperties: MaterialsProperties
    },
    DECORATIONS: {
      isExpanded: false,
      dependentProperties: DecorationsProperties
    },
    FINISHES: {
      isExpanded: false,
      dependentProperties: FinishesProperties
    },
    PURCHASE_CONDITION: {
      isExpanded: false,
      dependentProperties: PurchaseConditionProperties
    }
  },
  inputOptionsComputed: {}
};

export const BriefElementConfiguratorInitialState: BriefElementConfiguratorState = {
  0: BriefElementStateInitial
};

export const WindowInputs = [
  // "addWindow",
  "windowHeight",
  "windowHeight",
  "windowWidth",
  "windowId",
  "windowWantProtection"
];
