import { Box } from "@material-ui/core";
import { Form, FormikComputedProps, FormikProps } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MySwitchField } from "../../../../../components/common/form/MySwitchField";
import { MyTextField } from "../../../../../components/common/form/MyTextField";
import { MyButton } from "../../../../../components/common/MyButton";
import { TruckFormat } from "../../../../../services/truckFormat/type";
import { GenericForm } from "../../../../../utils/components/form";

interface TruckFormatFormProps
  extends FormikProps<TruckFormat>,
    FormikComputedProps<TruckFormat>,
    Partial<GenericForm> {
  hideFields?: Array<keyof TruckFormat>;
  hideButton?: boolean;
  loading?: boolean;
}

export const TruckFormatForm: FC<TruckFormatFormProps> = ({
  loading,
  mode,
  isValid
}) => {
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");

  return (
    <Form noValidate>
      <Box my={2}>
        <MyTextField
          name={"width"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={tCommon("width")}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"length"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={tCommon("length")}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"insideLength"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={t("truckFormat.insideLength")}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"insideWidth"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={t("truckFormat.insideWidth")}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"insideHeight"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={t("truckFormat.insideHeight")}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"weight"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={tCommon("weight")}
          endAdornment={<span style={{ color: "darkgrey" }}>kg</span>}
        />
        <MyTextField
          name="height"
          type="number"
          label={tCommon("height")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"purchaseCost"}
          type="number"
          label={t("truckFormat.truck-purchase-cost")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>€</span>}
        />
        <MyTextField
          name="maxHeight"
          type="number"
          label={t("truckFormat.maxHeight")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name="maxWeight"
          type="number"
          label={t("truckFormat.maxWeight")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>kg</span>}
        />
        <MyTextField
          name={"preferredUsageLevel"}
          type="number"
          label={t("truckFormat.preferred-usage-level")}
          numberFormatProps={{ allowNegative: false }}
        />
        <MySwitchField label={t("truckFormat.enable")} name={"enabled"} />
        <Box mt={2}>
          <MyButton
            type="submit"
            size="large"
            variant={isValid ? "contained" : "outlined"}
            color="primary"
            loading={loading}
          >
            {mode === "CREATE" ? tCommon("create") : tCommon("save")}
          </MyButton>
        </Box>
      </Box>
    </Form>
  );
};
